import React from 'react';


import HeroBanner from '../components/HeroBanner';
import AboutMe from '../components/AboutMe';
import MySkill from '../components/MySkill';
import BlogLanding from '../components/BlogLanding';
import PortfolioLanding from '../components/PortfolioLanding';
import ContactMe from '../components/ContactMe';

import './LandingPage.css';
import '../../index.css';

// ...

const LandingPage = () => {
    return (
        <div className="full-width">
            <HeroBanner />
            <AboutMe />
            <MySkill />
            <PortfolioLanding />
            <BlogLanding />
            <ContactMe />
        </div>
    );
};

export default LandingPage;
