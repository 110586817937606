import PropTypes from 'prop-types';
import {
  ChakraProvider,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  Text,
} from '@chakra-ui/react';

const CustomAccordionButton = ({ children }) => (
  <AccordionButton
    _expanded={{ bg: '#4682b4', color: 'white', borderRadius: '10px' }}
    sx={{
      borderRadius: '10px',
      mb: 2,
      _hover: {
        bg: '#8fc1e2',
        color: 'white' 
      }
    }}
  >
    {children}
    <AccordionIcon boxSize="24px" />
  </AccordionButton>
);

const SupportingSkills = ({ className = '' }) => {
  return (
    <ChakraProvider>
      <Box className={`frame-div ${className}`} flex="1">
        <Box className="frame-parent1">
          <Box className="title-container">
            <Text as="b" fontSize="2xl" className="title14">
              Supporting Skills
            </Text>
          </Box>
        </Box>
        <Box className="frame-gap">
          <Accordion allowMultiple>
            <AccordionItem>
              <h2>
                <CustomAccordionButton>
                  <Box flex="1" textAlign="left" display="flex" alignItems="center" >
                    <Icon viewBox="0 0 95 94" boxSize="30px" mr={2}>
                      <path
                        fill="currentColor"
                        d="M47.0718 0C38.9519 0 31.3027 2.23591 24.595 5.88397L62.4878 33.421L71.3137 6.94309C64.253 2.70663 55.8977 0 47.0718 0ZM14.7099 13.2978C5.76629 21.8884 0 33.774 0 47.1895C0 50.1314 0.235359 52.8381 0.706077 55.6624L37.069 29.5375L14.5923 13.2978H14.7099ZM80.9635 14.8276L66.6066 58.9574H92.3784C93.3198 55.1917 94.2612 51.3082 94.2612 47.1895C94.2612 34.5978 89.0833 23.3005 80.9635 14.8276ZM25.4188 52.7204L4.82486 67.4303C11.2972 80.7281 23.6536 90.8485 38.7165 93.5552L25.4188 52.7204ZM43.7768 70.7254L51.1906 93.9082C66.8419 92.4961 80.1397 83.5524 87.5535 70.7254H43.7768Z"
                      />
                    </Icon>
                    <Text as="b" fontSize="xl" className="title">UI/UX Design</Text>
                  </Box>
                </CustomAccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize="md" color="#1f1f1f">
                I possess basic design skills and continuously improve the UX of my products. When dealing with complex user flows that require minimal graphic design, you only need a BA like me to avoid time-consuming back-and-forth discussions between a BA and a designer.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <CustomAccordionButton>
                  <Box flex="1" textAlign="left" display="flex" alignItems="center">
                    <Icon viewBox="0 0 95 71" boxSize="30px" mr={2}>
                      <path
                        fill="currentColor"
                        d="M58.9133 0L23.5653 70.6959H35.348L70.6959 0H58.9133ZM11.7827 11.7827L0 35.348L11.7827 58.9133H23.5653L11.7827 35.348L23.5653 11.7827H11.7827ZM70.6959 11.7827L82.4786 35.348L70.6959 58.9133H82.4786L94.2612 35.348L82.4786 11.7827H70.6959Z"
                      />
                    </Icon>
                    <Text as="b" fontSize="xl" className="title">Full-stack Programming</Text>
                  </Box>
                </CustomAccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize="md" color="#1f1f1f">
                This portfolio website was coded by myself using the MERN stack. My technical knowledge enables me to challenge the quality of the product and design cost-effective solutions.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <CustomAccordionButton>
                  <Box flex="1" textAlign="left" display="flex" alignItems="center">
                    <Icon viewBox="0 0 95 95" boxSize="30px" mr={2}>
                      <path
                        fill="currentColor"
                        d="M0 0V94.2612H11.7827V0H0ZM23.5653 0V47.1306H47.1306V58.9133H94.2612L70.6959 35.7014L94.2612 11.7827H58.9133V0L23.5653 0Z"
                      />
                    </Icon>
                    <Text as="b" fontSize="xl" className="title">Product Management</Text>
                  </Box>
                </CustomAccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize="md" color="#1f1f1f">
              Products should be built with a clear vision and carefully prioritized. My mission is to reduce time-to-market while ensuring the product meets market needs, stays competitive, and achieves a high ROI.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <CustomAccordionButton>
                  <Box flex="1" textAlign="left" display="flex" alignItems="center">
                    <Icon viewBox="0 0 95 95" boxSize="30px" mr={2}>
                      <path
                        fill="currentColor"
                        d="M40.92 0C18.3555 0 0 18.3555 0 40.92C0 63.4844 18.3555 81.84 40.92 81.84C48.0517 81.84 54.8328 79.9693 60.6785 76.6957C60.9886 77.2293 61.3404 77.7376 61.7307 78.2156L73.4221 90.2578C74.5017 91.4725 75.8181 92.4538 77.2906 93.1414C78.7632 93.8289 80.3607 94.2083 81.9851 94.2561C83.6095 94.3039 85.2266 94.0192 86.737 93.4194C88.2474 92.8197 89.6193 91.9175 90.7684 90.7684C91.9175 89.6193 92.8197 88.2474 93.4194 86.737C94.0192 85.2266 94.3039 83.6095 94.2561 81.9851C94.2083 80.3607 93.8289 78.7632 93.1414 77.2906C92.4538 75.8181 91.4725 74.5017 90.2578 73.4221L78.2156 61.7307C77.7376 61.3404 77.2293 60.9886 76.6957 60.6785C79.8524 54.8328 81.84 48.0517 81.84 40.92C81.84 18.3555 63.4844 0 40.92 0ZM40.92 11.6914C57.1711 11.6914 70.1485 24.6689 70.1485 40.92C70.1485 47.8179 67.8102 54.2482 63.9521 59.1586C63.8352 59.2755 63.7183 59.3924 63.6013 59.5093C63.3637 59.6184 63.1297 59.7354 62.8999 59.8601C61.7277 60.757 60.7359 61.8678 59.977 63.1337C54.8328 67.4595 48.1687 70.0316 40.92 70.0316C24.6689 70.0316 11.6914 57.0541 11.6914 40.8031C11.6914 24.552 24.6689 11.5745 40.92 11.5745V11.6914ZM35.0743 23.3828V35.0743H23.3828V46.7657H35.0743V58.4571H46.7657V46.7657H58.4571V35.0743H46.7657V23.3828H35.0743Z"
                      />
                    </Icon>
                    <Text as="b" fontSize="xl" className="title">Testing</Text>
                  </Box>
                </CustomAccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize="md" color="#1f1f1f">
                Quality assurance is crucial. From design to quality verification, I streamline the entire process, especially when timelines are tight.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

SupportingSkills.propTypes = {
  className: PropTypes.string,
};

export default SupportingSkills;
