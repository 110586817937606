import React, {useEffect, useRef, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Autoplay, Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import 'swiper/swiper-bundle.css';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';


import './Carousel.css';
import DevelopmentWarningPopup from '../../shared/components/WarningDialog';


SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow]);

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Carousel = () => {
  const swiperRef = useRef(null); 
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedPortfolio, setLoadedPortfolio] = useState([]);
  const [items, setItems] = useState([]);
  const [activeSlide, setActiveSlide] = useState({
    title: '', 
    snippet: '', 
    link: '' 
  });
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    const fetchPortfolio = async () => {
      try { 
        const getPortfolioEndpoint = `${backendUrl}/api/portfolio`;

        const responseData = await sendRequest(getPortfolioEndpoint);
        setItems(responseData.portfolio);
        if (responseData.portfolio.length > 0) {
          setActiveSlide({
            title: responseData.portfolio[0].title,
            snippet: responseData.portfolio[0].snippet,
            link: responseData.portfolio[0].link
          });
        }
      } catch (err) {}
  };
  fetchPortfolio();
}, [sendRequest]);


  const handleMouseEnter = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.start();
    }
  };

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.realIndex;
    const activeItem = items[activeIndex];
    if (activeItem) {
      setActiveSlide({
        title: activeItem.title,
        snippet: activeItem.snippet,
        link: activeItem.link
      });
    }
  };



  return (
    <>
    <div 
      className="carousel-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      >
      <Swiper
        ref={swiperRef}
        effect="coverflow"
        slidesPerView={3}
        onSlideChange={handleSlideChange}
        centeredSlides={true}
        loop={true}
        // loopedSlides={7}
        loopAdditionalSlides={2} 
        loopFillGroupWithBlank={true}
        watchSlidesProgress={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper; 
        }}
        
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: -30,
          depth: 300,
          modifier: 1,
          slideShadows: true
        }}
        spaceBetween={-100}
        slideToClickedSlide={true}
        onProgress={(swiper) => {
          swiper.slides.forEach((slide) => {
            const slideProgress = slide.progress;
            let scale = 1;
            let opacity = 1;
            if (Math.abs(slideProgress) > 1.5) {
              scale = 0.5;
              opacity = 0.3;
            } else if (Math.abs(slideProgress) > 0.5) {
              scale = 0.7;
              opacity = 0.6;
            } 
            slide.style.transform = `scale(${scale})`;
            slide.style.opacity = `${opacity}`;
          });
        }}
        onSetTransition={(swiper, transition) => {
          swiper.slides.forEach(slide => {
            slide.style.transition = `transform ${transition}ms, opacity ${transition}ms`;
          });
        }}
        onSlideChangeTransitionEnd={(swiper) => {
          swiper.update(); 
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="swiper-carousel-animate-opacity">
              <div className="slide-overlay">
                <div className="slide-hashtag">
                  {item.hashtag.map((tag, index) => (
                    <span key={index}>{tag}</span>
                  ))}
                </div>
              </div>
              <img src={item.imageURL} alt={item.content} />
            </div>
          </SwiperSlide>
        ))}
        <div className="pagination-navigation-wrapper">
          <div className="swiper-button-prev" /> 
          <div className="swiper-pagination" />
          <div className="swiper-button-next" /> 
        </div>
      </Swiper>
    </div>
    <div className="active-slide-content">
        <div className="slide-title-wrapper">
          <h2>{activeSlide.title}</h2>
          <a
            href="#"
            onClick={handleButtonClick}
            className="svg-link"
          >          
          <svg className="svg-icon" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="34" height="34" rx="17.5" transform="matrix(-1 0 0 1 35.6086 0.861694)" fill="#4682B4"/>
            <path d="M24.2339 10.9241L11.9839 23.1741" stroke="white" stroke-width="1.3125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.2339 19.9103V10.9241H15.2476" stroke="white" stroke-width="1.3125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </a>
        </div>
        <p>{activeSlide.snippet}</p>
      </div>
      <DevelopmentWarningPopup isOpen={isPopupVisible} onClose={handleClosePopup} />
    </>
  );
};

export default Carousel;