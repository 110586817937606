import React, { useEffect, useState } from 'react';

import BlogList from '../components/BlogList';
import BlogCardHero from '../components/BlogCardHero';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './Blog.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;


const Blog = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedBlog, setLoadedBlog] = useState();

  useEffect(() => {
    const fetchBlog = async () => {
      const getBlogsEndpoint = `${backendUrl}/api/blog`;
      try { 
        const responseData = await sendRequest(getBlogsEndpoint);
  
        setLoadedBlog(responseData.blog);
      } catch (err) {}
  };
  fetchBlog();
}, [sendRequest]);

const heroBlog = loadedBlog?.find(blog => blog.heroBlog === true);

  return (
    <div className='blog-page'>
      <div className='blog-page-heading'>
        <h1 className='blog-page-title'>Tech Insights and Digital Adventures</h1>
        <div className='blog-page-description'>
            <p className="welcome-to-my-blog">{`Welcome to my blog. `}</p>
            <p className="here-i-share">
              Here, I share my passion for building beautiful, efficient, and
              budget-friendly digital products.
            </p>
        </div>
      </div>
    {/* <ErrorModal error={error} onClear={clearError}/> */}
    <div className='blog-page-wrapper'>
      {!isLoading && heroBlog && <BlogCardHero {...heroBlog} image={heroBlog.imageURL} />}
      <div className='more-blog-wrapper'>
        <h2 className="more-blog-text">Explore more insights and stories below</h2>
        {!isLoading && loadedBlog && <BlogList items={loadedBlog}/>}
      </div>
    </div>
    </div>
  );
};

export default Blog;
