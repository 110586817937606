import React, { useRef, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Link,
} from "@chakra-ui/react";

function DevelopmentWarningPopup({ isOpen, onClose }) {
  const cancelRef = useRef();
  const history = useHistory();
  const location = useLocation();

  const handleContactClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    onClose();
    if (location.pathname !== targetPath || location.hash !== '#contactMeSection') {
      history.push(`${targetPath}#contactMeSection`);
    } else {
      scrollToSection('contactMeSection');
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = 40;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
    } else {
      console.error(`Target section ${sectionId} not found.`);
    }
  };

  useEffect(() => {
    if (location.hash === '#contactMeSection') {
      setTimeout(() => {
        scrollToSection('contactMeSection');
      }, 0);
    }
  }, [location.hash]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <i className="fas fa-exclamation-triangle icon" style={{ color: 'orange', marginRight: '8px' }}></i>
            Feature Under Development
          </AlertDialogHeader>

          <AlertDialogBody>
            I apologize for the inconvenience, but this feature is currently under development. <br /> <br />
            If you have any questions, please feel free to reach out!
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleContactClick}
              ml={3}
            >
              Contact Me
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DevelopmentWarningPopup;
