import React from 'react';
import { useField, ErrorMessage } from 'formik';

import './TextInput.css';
import { color } from 'framer-motion';

const styles = {
    icon: {
      marginRight: '3px',
      fontSize: '1.3em',
      aligncontent: 'center',
      color: 'red'
    }
  };
  
  const CustomInlineError = ({ children }) => {
    return (
        <div className="errorMessage">
            <i className="fas fa-times icon" style={styles.icon}></i>
            {children}
        </div>
    );
  };


const TextInput = ({ as = "input", onChangeHandler, ...props }) => {
  const [field, meta] = useField(props);
  const Component = as;

  return (
    <div className="form-group">
      <Component
        {...field}
        {...props}
        className={meta.touched && meta.error ? 'input-error' : ''}
        onChange={(e) => {
          field.onChange(e);
          if (onChangeHandler) onChangeHandler(e);
        }}
      />
      <ErrorMessage name={props.name} component={CustomInlineError} />
    </div>
  );
};

export default TextInput;
