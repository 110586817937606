import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text
} from '@chakra-ui/react';

const TermOfServiceModal = ({ isOpen, onClose, size }) => {
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size={size} isCentered>
      <ModalOverlay  />
      <ModalContent 
          bg="white" 
          p={6} 
          borderRadius="4px" 
          boxShadow="lg" 
          width="90%" 
          maxWidth="600px" 
          maxHeight="90vh"
          overflowY="auto"
          >
        <Flex justifyContent="space-between" alignItems="stretch">
          <ModalHeader whiteSpace="nowrap">Terms Of Service</ModalHeader>
          <ModalCloseButton position="relative"  />
        </Flex>
        <ModalBody>
          <Flex alignItems="center" mb="1rem">
            <Text fontWeight="bold" mr="1rem">
              Effective Date:
            </Text>
            <Text>
              25th July 2024
            </Text>
          </Flex>
          <Text fontWeight="semibold" mr="1rem" mt="0.5rem" >1. Acceptance of Terms</Text>
          <p>By accessing and using this website, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this service.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >2. Changes to Terms</Text>
          <p>We reserve the right to change, modify, or update these terms at any time without prior notice. Your continued use of the website after any changes indicates your acceptance of the new terms.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >3. Description of Service</Text>
          <p>This website provides visitors with access to my portfolio, blog posts, and contact information. Users can also subscribe to receive updates and new blog posts.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >4. User Conduct</Text>
          <p>Users agree to use the website only for lawful purposes. You agree not to take any action that might compromise the security of the website, render the website inaccessible to others, or otherwise cause damage to the website or its content.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >5. Intellectual Property</Text>
          <p>All content on this website, including text, images, graphics, and code, is the property of Trong Tuong Pham unless otherwise noted. Unauthorized use or duplication of any material without express and written permission is strictly prohibited.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >6. Privacy Policy</Text>
          <p>Your use of the website is also governed by our Privacy Policy, which is incorporated into these Terms of Service by this reference. Please review our Privacy Policy to understand our practices.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >7. Subscriptions</Text>
          <p>Users may subscribe to receive email updates for new blog posts. By subscribing, you consent to receive email communications from us. You can unsubscribe at any time by following the instructions in the emails you receive.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >8. Contact Information</Text>
          <p>If you choose to contact us through the website, you agree to provide accurate and truthful information. We will use the information you provide to respond to your inquiries.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >9. Disclaimer of Warranties</Text>
          <p>This website is provided "as is" without any representations or warranties, express or implied. We make no representations or warranties in relation to this website or the information and materials provided on this website.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >10. Limitation of Liability</Text>
          <p>In no event shall Trong Tuong Pham be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use this website, even if Trong Tuong Pham has been advised of the possibility of such damages.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >11. Contact Us</Text>
          <p>If you have any questions about these Terms of Service, please contact us at tuongpt3@gmail.com.</p>
        </ModalBody>
        <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>        
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermOfServiceModal;
