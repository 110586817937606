import React from 'react';

import './PortfolioLanding.css';
import '../../index.css';
import Carousel from '../../portfolio/components/Carousel';

const PortfolioLanding = () => {
    return (
    <div className="portfolio-section" id="myPortfolioSection">
        <div className="portfolio-section-background" />
        <section className="section-inner-container">
            <div className="section-header whitetext">
            <h1 className="section-title">My Portfolio</h1>
            <div className="section-description whitetext">
                <p>
                Explore my comprehensive IT services, from business analysis and UI/UX design to programming, product management, and testing. Let's create outstanding digital solutions together.
                </p>
            </div>
            </div>
        </section>
        <Carousel />
    </div>
    );
};

export default PortfolioLanding;