import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';


import BlogList from '../../blogs/components/BlogList';
import BlogCardHero from '../../blogs/components/BlogCardHero';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './BlogLanding.css';
import '../../index.css';
import '../../blogs/components/BlogCard.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const BlogLanding = () => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedBlog, setLoadedBlog] = useState([]);
    const [topBlogs, setTopBlogs] = useState([]);

    const history = useHistory();
  
    useEffect(() => {
      const fetchBlog = async () => {
        const getBlogsEndpoint = `${backendUrl}/api/blog`;
        try {
          const responseData = await sendRequest(getBlogsEndpoint);
  
          // Assuming responseData.blog is an array of blogs
          const sortedBlogs = responseData.blog.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          const heroBlog = sortedBlogs.find(blog => blog.heroBlog === true);
          const topBlogs = sortedBlogs.filter(blog => !blog.heroBlog).slice(0, 4);

          if (heroBlog) {
            setTopBlogs([heroBlog, ...topBlogs]);
          } else {
            setTopBlogs(topBlogs);
          }
        } catch (err) {}
      };
      fetchBlog();
    }, [sendRequest]);

    const navigateToBlog = () => {
        history.push('/blog');
        window.scrollTo(0, 0); 
    };
  
    return (
      <div className="blog-landingpage">
        <div className='section-inner-container container1192'>
          <div className='section-header'>
            <h1 className='section-title'>Tech Insights and Digital Adventures</h1>
            <div className='section-description'>
              <p> Discover industry insights, innovations and expert tips in Business Analysis, UX Design, and Technology. </p>
              <p>
              Stay updated with the latest trends in web design and user experience.
              </p>
            </div>
          </div>
          {/* <ErrorModal error={error} onClear={clearError}/> */}
          <div className='blog-page-wrapper'>
            {!isLoading && topBlogs.length > 0 && (
              <div>
                <BlogCardHero {...topBlogs[0]} image={topBlogs[0].imageURL} />
                <div className='more-landingblogs-wrapper'>
                  <BlogList items={topBlogs.slice(1)} />
                </div>
              </div>
            )}
          </div>
          <div class="ctas">
              <button class="button-normal" onClick={navigateToBlog}><b>View More</b></button>
          </div>
        </div>
      </div>
    );
  };

export default BlogLanding;
