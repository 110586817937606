import React, { useState } from 'react';

import './BlogCard.css';
import DevelopmentWarningPopup from '../../shared/components/WarningDialog';

const BlogCard = ({ className = "", image, title, content, createdDate, topic }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  
  return (
    <div className={`card-02 ${className}`}>
      <img src={image} loading="lazy" alt="" className='image-icon'/>
      <div className='blog-content'>
        <div className='container1'>
          <div className='blog-meta'>{createdDate}, {topic}</div>
          <h3 className='blog-title'>{title}</h3>
          <div className='blog-snippet'>{content}</div>
        </div>
        <button className="ctalink" onClick={handleButtonClick}>
          <div className="read-more-wrapper">
            <b className="read-more">Read More</b>
          </div>
          <svg
            className="arrow-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 17"
            fill="#000"
            width="18px"
            height="18px"
            >
            <path d="M5.94 14.1005L10.2867 9.75386C10.8 9.24053 10.8 8.40053 10.2867 7.88719L5.94 3.54053" />
          </svg>
      </button>
      </div>
      <DevelopmentWarningPopup isOpen={isPopupVisible} onClose={handleClosePopup} />
    </div>
  );
};

export default BlogCard;