import React, {useRef, useState} from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {  useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Spinner } from '@chakra-ui/react';

import TextInput from '../../shared/components/FormElements/TextInput';


import '../../index.css';
import './ContactMe.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ContactMe = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [customMessage, setCustomMessage] = useState('');

    // Form validation schema
    const validationSchema = Yup.object({
        name: Yup.string().max(50, 'Name must be 50 characters or less').required('Name is required'),
        email: Yup.string().email('Invalid email address').max(100, 'Email must be 100 characters or less').required('Email is required'),
        phoneNumber: Yup.string().matches(
            /^\+?[1-9]\d{1,14}$/,
            'Invalid phone number'
        ).max(15, 'Phone number must be 15 characters or less'),
        message: Yup.string().max(500, 'Message must be 500 characters or less').required('Message is required'),
        // recaptcha: Yup.string().required('Please verify that you are not a robot')
    });

    // Initial form values
    const initialValues = {
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        recaptcha: ''
    };

    // Form submission handler
    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            setSubmitting(false);
            return;
        }

        try {
            const token = await executeRecaptcha('contact_me_form');
            console.log('reCAPTCHA token:', token); // Debugging log
            values.recaptcha = token;
            
            const getEmailEndpoint = `${backendUrl}/api/send`;

            const response = await axios.post(getEmailEndpoint, values);
            setCustomMessage('Thank you for contacting me! I will get back to you soon.');
            setSubmitting(false);
            resetForm();
            // alert('Form submitted successfully!');
        } catch (error) {
            console.error('FAILED...', error);
            setSubmitting(false);
            alert('Server Error. Please reach out to me via social media.');
        }
    };

    return (
        <div className="contact-me-section" id="contactMeSection">
            <div className="contact-me-inner-section">
                <div className="contact-me-info" >
                    <h1 className="section-title" style={{color: 'var(--pastel-blue)'}}>Contact Me</h1>
                    <p style={{color: 'var(--pastel-blue)'}}>Have a question or a project in mind? I'd love to hear from you. Let's chat and make something amazing together.</p>
                    <div className="contact-details">
                        <div className="contact-location-phone">
                            <div className="contact-item">
                                <svg width="24" height="24" viewBox="0 0 24 24" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                                    <path d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM12 3C12.99 3 13.92 3.27 14.82 3.57C14.19 4.17 13.47 4.71 13.59 5.25C13.71 5.79 15.66 5.64 15.66 6.75C15.66 7.56 14.4 7.8 15.27 8.73C16.32 9.78 13.35 11.67 13.29 13.05C13.2 15.54 15.81 15.96 17.88 15.96C19.14 15.96 19.47 16.56 19.38 17.28C17.76 19.59 15 21.03 11.97 21.03C10.83 21.03 9.78 20.76 8.79 20.37C9.45 19.05 7.95 16.44 6.54 15.6C5.85 14.91 4.38 15.18 3.54 14.85C3.27 14.04 3 13.23 2.97 12.33C3.06 12.18 3.21 12.06 3.45 12.06C4.02 12.06 4.8 13.2 5.22 13.08C5.76 12.96 3 9.15 4.29 8.4C4.89 8.04 6.09 9.57 5.7 7.92C5.34 6.39 6.78 7.08 7.68 6.69C8.46 6.36 9.03 5.46 8.07 4.92C7.89 4.83 7.68 4.62 7.41 4.35C8.76 3.54 10.32 3.03 12 3.03V3ZM18.93 6.27C19.47 6.93 19.89 7.65 20.25 8.43V8.52C20.13 8.73 19.92 8.85 19.59 9.18C18.75 10.02 18.63 8.55 18.27 8.25C17.88 7.89 16.47 8.31 16.29 7.86C16.08 7.32 17.79 6.6 18.93 6.27Z"/>
                                </svg>
                                <div className="contact-item-details">
                                    <p>Ho Chi Minh, Vietnam</p>
                                    <p>Kuala Lumpur, Malaysia</p>
                                </div>
                            </div>
                            <div className="contact-item">
                                <svg width="24" height="24" viewBox="0 0 24 24" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                                    <path d="M20 10.999H22C22 5.869 18.127 2 12.99 2V4C17.052 4 20 6.943 20 10.999Z" fill="black" />
                                    <path d="M13 7.99999C15.103 7.99999 16 8.89699 16 11H18C18 7.77499 16.225 5.99999 13 5.99999V7.99999ZM16.422 13.443C16.2299 13.2683 15.9774 13.1752 15.7178 13.1832C15.4583 13.1912 15.212 13.2998 15.031 13.486L12.638 15.947C12.062 15.837 10.904 15.476 9.71204 14.287C8.52004 13.094 8.15904 11.933 8.05204 11.361L10.511 8.96699C10.6975 8.78612 10.8062 8.53982 10.8142 8.2802C10.8222 8.02059 10.7289 7.76804 10.554 7.57599L6.85904 3.51299C6.68408 3.32035 6.44092 3.2035 6.18119 3.18725C5.92146 3.17101 5.66564 3.25665 5.46804 3.42599L3.29804 5.28699C3.12515 5.46051 3.02196 5.69145 3.00804 5.93599C2.99304 6.18599 2.70704 12.108 7.29904 16.702C11.305 20.707 16.323 21 17.705 21C17.907 21 18.031 20.994 18.064 20.992C18.3086 20.9783 18.5394 20.8747 18.712 20.701L20.572 18.53C20.7415 18.3325 20.8273 18.0768 20.8113 17.817C20.7952 17.5573 20.6785 17.3141 20.486 17.139L16.422 13.443Z" fill="black" />                                </svg>
                                <div className="contact-item-details">
                                    <p>(+84)94 554 8846</p>
                                    <p>(+60)17 642 3220</p>
                                </div>
                            </div>
                        </div>
                        <div className="contact-item">
                            <svg width="24" height="24" viewBox="0 0 24 24" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                                <path d="M22 4H2V20H22V4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="black"/>
                            </svg>
                            <a href="mailto:tuongpt3@gmail.com">tuongpt3@gmail.com</a>
                        </div>
                        <div className="contact-item">
                            <svg width="24" height="24" viewBox="0 0 24 24" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                                <g clip-path="url(#clip0_436_473)">
                                <path d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z" fill="black"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_436_473">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                            <a href="https://www.linkedin.com/in/tuongpt3" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/tuongpt3/</a>
                        </div>
                        <div className="contact-item">
                            <svg width="24" height="24" viewBox="0 0 24 24" style={{marginRight: '8px', verticalAlign: 'middle'}}>
                                <g clip-path="url(#clip0_436_535)">
                                <path d="M9.28786 8.76459H8.94528H6.66138V10.8582H9.11657C9.55432 10.8582 9.93497 10.744 9.93497 9.73525C9.93497 8.72653 9.28786 8.76459 9.28786 8.76459Z" fill="black"/>
                                <path d="M9.28786 12.3806H6.66138V15.0452H8.96431C9.00238 15.0452 9.05947 15.0452 9.1356 15.0452C9.51625 15.0452 10.2395 14.8929 10.2395 13.751C10.2585 12.3996 9.28786 12.3806 9.28786 12.3806Z" fill="black"/>
                                <path d="M17.9667 0.00952148H6.01427C2.70262 0.00952148 0 2.71214 0 6.0238V17.9952C0 21.2879 2.70262 23.9905 6.01427 23.9905H17.9857C21.2974 23.9905 24 21.2879 24 17.9762V6.0238C23.981 2.71214 21.2784 0.00952148 17.9667 0.00952148ZM14.0841 7.62253H18.0809V8.57415H14.0841V7.62253ZM12.2189 13.8842C12.2189 16.8533 9.28787 16.9485 9.28787 16.9485H6.58525H6.50912H4.5678V7.05155H6.49009H6.56622H9.26883C10.7343 7.05155 11.8953 7.88898 11.8953 9.63997C11.8953 11.391 10.4869 11.4861 10.4869 11.4861C12.3521 11.4671 12.2189 13.8842 12.2189 13.8842ZM19.3941 13.7129H14.5789C14.5789 15.6162 16.2157 15.4639 16.2157 15.4639C17.7573 15.4639 17.7002 14.4742 17.7002 14.4742H19.337C19.337 17.1388 16.1586 17.0246 16.1586 17.0246C12.3521 17.0246 12.5995 13.2752 12.5995 13.2752C12.5995 13.2752 12.5995 9.50675 16.1586 9.50675C19.908 9.48771 19.3941 13.7129 19.3941 13.7129Z" fill="black"/>
                                <path d="M16.1966 10.8772C14.7692 10.8772 14.5598 12.3808 14.5598 12.3808H17.605C17.605 12.3808 17.624 10.8772 16.1966 10.8772Z" fill="black"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_436_535">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                            <a href="https://www.behance.net/tngphmtrng" target="_blank" rel="noopener noreferrer">https://www.behance.net/tngphmtrng</a>
                        </div>     
                    </div>
                </div>
                <div className="contact-me-form">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                        <Form>
                            <TextInput
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                            />
                            <TextInput
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email address"
                            />
                            <TextInput
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Phone number (optional)"
                            />
                            <TextInput
                                as="textarea"
                                id="message"
                                name="message"
                                placeholder="Your message"
                                onChangeHandler={() => setCustomMessage('')}
                            />
                            <ErrorMessage name="recaptcha" component="div" className="error" />
                            <button type="submit" className='button1' disabled={isSubmitting}>
                                {isSubmitting ? <Spinner size="sm" /> : 'Submit'}
                            </button>
                            {customMessage && (
                                <div className={`message ${customMessage.includes('Thank you') ? 'success' : 'error'}`}>
                                {customMessage.includes('Thank you') ? (
                                    <i className="fas fa-check icon"></i>
                                ) : (
                                    <i className="fas fa-times icon"></i>
                                )}
                                {customMessage}
                                </div>
                            )}   
                        </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};



export default ContactMe;
