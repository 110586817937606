import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Spinner, Link } from '@chakra-ui/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TextInput from './FormElements/TextInput';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import TermOfServiceModal from './TermOfServiceModal';


import './footer.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const FooterForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [customMessage, setCustomMessage] = useState('');

  // Form validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // recaptcha: Yup.string().required('Please verify that you are not a robot')
  });

  const initialValues = {
    email: '',
    recaptcha: ''
  };

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setSubmitting(false);
      return;
    }
    
    try {
      const token = await executeRecaptcha('subscribe_newsletter');
      console.log('reCAPTCHA token:', token); // Debugging log
      values.recaptcha = token;
      
      const getSubscribeEndpoint = `${backendUrl}/api/subscribe`;

      await axios.post(getSubscribeEndpoint, values);
      setCustomMessage('Thank you for subscribing!');
      resetForm();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setCustomMessage('Subscriber already exists');
      } else {
        alert('Server Error. Please reach out to me via social media');
      }
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
    {({ isSubmitting }) => (
        <Form className="form">
          <div className="form-group">
            <TextInput
                type="email"
                id="email"
                name="email"
                placeholder="Email address"
                onChangeHandler={() => setCustomMessage('')}
            />
              {customMessage && (
                <div className={`message ${customMessage.includes('Thank you') ? 'success' : 'error'}`}>
                  {customMessage.includes('Thank you') ? (
                    <i className="fas fa-check icon"></i>
                  ) : (
                    <i className="fas fa-times icon"></i>
                  )}
                  {customMessage}
                </div>
              )}          
          </div>
            <ErrorMessage name="recaptcha" component="div" className="error" />
            <button type="submit" className="button" disabled={isSubmitting}>
                {isSubmitting ? <Spinner size="sm" /> : 'Subscribe'}
            </button>
        </Form>
    )}
</Formik>
);
};

const Footer = () => {
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState(false);

  const openPrivacyModal = () => setPrivacyModalOpen(true);
  const closePrivacyModal = () => setPrivacyModalOpen(false);

  const openTermsModal = () => setTermsModalOpen(true);
  const closeTermsModal = () => setTermsModalOpen(false);

  const history = useHistory();
  const location = useLocation();

  const handleServicesClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#mySkillSection') {
      history.push(`${targetPath}#mySkillSection`);
    } else {
      scrollToSection('mySkillSection');
    }
  };

  const handlePortfolioClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#myPortfolioSection') {
      history.push(`${targetPath}#myPortfolioSection`);
    } else {
      scrollToSection('myPortfolioSection');
    }
  };

  const handleContactClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#contactMeSection') {
      history.push(`${targetPath}#contactMeSection`);
    } else {
      scrollToSection('contactMeSection');
    }
  };

  const handleAboutClick = (e) => {
    e.preventDefault(); 
    history.push('/'); 
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  };

  const handleBlogClick = (e) => {
    e.preventDefault(); 
    history.push('/blog'); 
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  };



  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = 40;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
    } else {
      console.error(`Target section ${sectionId} not found.`);
    }
  };

  useEffect(() => {
    if (location.hash === '#mySkillSection') {
      scrollToSection('mySkillSection');
    } else if (location.hash === '#myPortfolioSection') {
      scrollToSection('myPortfolioSection');
    } else if (location.hash === '#contactMeSection') {
      scrollToSection('contactMeSection');
    } 
  }, [location.hash]);

  return (
    <footer className="footer">
      <div className="newsletter-container">
        <h2 className="newsletter-heading">Let’s stay in touch!</h2>
        <p className="newsletter-subheading">Get blogs and industry insights straight to your inbox</p>
        <FooterForm />
        <p className="privacy-text">
          By signing up, you consent to our 
          <Link onClick={openPrivacyModal} color="blue.500" textDecoration="underline">
            Privacy Policy
          </Link>
        </p>
        <PrivacyPolicyModal isOpen={isPrivacyModalOpen} onClose={closePrivacyModal} />
        </div>
      <div className="footer-bottom">
      <nav className="nav">
        <Link className="link" to="/" onClick={handleAboutClick}>About</Link>
        <Link className="link" to="/" onClick={handleServicesClick}>Services</Link>
        <Link className="link"to="/" onClick={handlePortfolioClick}>Portfolio</Link>
        <Link className="link" to="/blog" onClick={handleBlogClick}>Blog</Link>
        <Link className="link"to="/" onClick={handleContactClick}>Contact</Link>
      </nav>
      <div className="social-media-container">
        <h4 className="follow-me">Follow Me</h4>
        <div className="icons">
          <a href="https://www.linkedin.com/in/tuongpt3" target="_blank" rel="noopener noreferrer">
            <img src="/images/linkedin-icon.png" alt="LinkedIn" className="social-icon" />
          </a>
          <a href="https://www.behance.net/tngphmtrng" target="_blank" rel="noopener noreferrer">
            <img src="images/behance-icon.png" alt="Behance" className="social-icon" />
          </a>
          <a href="https://www.facebook.com/meoden36" target="_blank" rel="noopener noreferrer">
            <img src="images/facebook-icon.png" alt="Facebook" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/meoden36" target="_blank" rel="noopener noreferrer">
            <img src="images/instagram-icon.png" alt="Instagram" className="social-icon" />
          </a>
        </div>
      </div>
        <p>© 2024 Trong Tuong Pham. All rights reserved.</p>
        <p>
          <Link onClick={openPrivacyModal}>Privacy Policy</Link>
          <span> | </span>
          <Link onClick={openTermsModal}>Terms of Service</Link>
          <PrivacyPolicyModal isOpen={isPrivacyModalOpen} onClose={closePrivacyModal} />
          <TermOfServiceModal isOpen={isTermsModalOpen} onClose={closeTermsModal} />
        </p>
      </div>
    </footer>
  );
};

export default Footer;
