import { React, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import './HeroBanner.css';


const HeroBanner = () => {
  const history = useHistory();
  const location = useLocation();

  const handleContactClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#contactMeSection') {
      history.push(`${targetPath}#contactMeSection`);
    } else {
      scrollToSection('contactMeSection');
    }
  };
  
  const handlePortfolioClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#myPortfolioSection') {
      history.push(`${targetPath}#myPortfolioSection`);
    } else {
      scrollToSection('myPortfolioSection');
    }
  };
  
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = 40;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
    } else {
      console.error(`Target section ${sectionId} not found.`);
    }
  };
  
  useEffect(() => {
    if (location.hash === '#myPortfolioSection') {
      scrollToSection('myPortfolioSection');
    } else if (location.hash === '#contactMeSection') {
      scrollToSection('contactMeSection');
    } 
  }, [location.hash]);

  return (
    <section className="hero">
      <div className="content">
        <div className="main-content">
          <div className="header-content">
            <div className="section-heading">
              <div className="title1">IT Business Analyst</div>
              <h1 className="title2">
                <p className="hi-there-im">{`Hi There, I’m `}</p>
                <p className="louis1">Louis</p>
              </h1>
              <div className="description1">
                Welcome to my portfolio of captivating digital experiences. Explore my work and let's create something extraordinary together.
              </div>
            </div>
            <div className="ctas">
              <button className="button1" onClick={handleContactClick}>
                <b className="contact-me">Contact Me</b>
              </button>
              <button className="button2" onClick={handlePortfolioClick}>
                <b className="portfolio1">Portfolio</b>
              </button>
            </div>
          </div>
          <div className="shape-content-wrapper">
            <div className="shape-content">
              <img
                className="shape-117-icon"
                loading="lazy"
                alt=""
                src="/images/shape_117.svg"
              />
              <div className="experience">
                {/* <div className="BAtext">
                  <b className="ba">BA</b>
                  <b className="a">A</b>
                </div> */}
                <div className="BAtext">
                  <img src="/images/BAtext.png" alt="Business Analyst" />
                </div>
                <div className="shape-two-container">
                  <img
                    className="shape-73-icon"
                    loading="lazy"
                    alt=""
                    src="/images/shape_73.svg"
                  />
                  {/* <div className="empty-shape-container"> */}
                    <img className="hero-picture" alt="" src="/images/ProfileAndV.png" />
                    {/* <img
                      className="empty-shape-icon"
                      loading="lazy"
                      alt=""
                      src="/images/3v.svg"
                    />
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
