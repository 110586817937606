import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import NewPlace from './places/pages/NewPlace';
import LandingPage from './landingpage/pages/LandingPage';
import Blog from './blogs/pages/Blog';
import NavBar from './shared/components/navbar';
import Footer from './shared/components/footer';

const App = () => {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route path="/" exact>
          <LandingPage />
        </Route>
        <Route path="/places/new" exact>
          <NewPlace />
        </Route>
        <Route path="/blog" exact>
          <Blog />
        </Route>
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
