import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import SupportingSkills from "../components/SupportingSkills";

import './MySkill.css';
import '../../index.css';


const MySkill = () => {
    const [coreSkillRef, coreSkillInView] = useInView({
      triggerOnce: true,
      threshold: 0.2,
    });

    const [supportingSkillsRef, supportingSkillsInView] = useInView({
      triggerOnce: true,
      threshold: 0.2,
    });

    const [showSupportingSkills, setShowSupportingSkills] = useState(false);

    useEffect(() => {
      if (supportingSkillsInView) {
          const timer = setTimeout(() => {
              setShowSupportingSkills(true);
          }, 2000);
          return () => clearTimeout(timer);
      }
    }, [supportingSkillsInView]);

    return (
      <div className="services-section">
        <div className="myskill-section" id="mySkillSection">
        <section className="section-inner-container">
          <div className="section-header">
            <h1 className="section-title">My Skills</h1>
            <div className="section-description">
              <p>
                I extend my abilities beyond the traditional scope of Business
                Analysis to streamline development processes.
              </p>
              <p>
                By minimizing the need for multiple roles, I reduce costs and
                increase efficiency. As a Business Analyst, I am the core of the
                development process, driving innovation and breakthroughs through
                cross-functional knowledge.
              </p>
            </div>
          </div>
        </section>
        <section className="myskill-content" >
          <div className="myskill-frame-group">
            <div 
              ref={coreSkillRef}
              className={`myskill-frame-container animate__animated ${
                coreSkillInView ? 'animate__jackInTheBox' : ''
              }`}>
              <div className="core-supporting-skill">
                <b className="title-core">Core Skill</b>
              </div>
              <div className="services-core">
                <div className="services-core-inner">
                  <div className="vector-ITBA">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 95 95"
                      fill="#fff"
                      width="35px"
                      height="35px"
                    >
                      <path d="M47.1306 0C21.2088 0 0 21.2088 0 47.1306C0 73.0525 21.2088 94.2612 47.1306 94.2612C73.0525 94.2612 94.2612 73.0525 94.2612 47.1306C94.2612 21.2088 73.0525 0 47.1306 0ZM47.1306 11.7827C66.6898 11.7827 82.4786 27.5714 82.4786 47.1306C82.4786 66.6898 66.6898 82.4786 47.1306 82.4786C27.5714 82.4786 11.7827 66.6898 11.7827 47.1306C11.7827 27.5714 27.5714 11.7827 47.1306 11.7827ZM70.6959 23.5653L35.348 35.348L23.5653 70.6959L58.9133 58.9133L70.6959 23.5653ZM47.1306 41.2393C50.4298 41.2393 53.0219 43.8315 53.0219 47.1306C53.0219 50.4298 50.4298 53.0219 47.1306 53.0219C43.8315 53.0219 41.2393 50.4298 41.2393 47.1306C41.2393 43.8315 43.8315 41.2393 47.1306 41.2393Z" />
                    </svg>
                    <b className="title-ITBA">IT Business Analyst</b>
                  </div>
                </div>
                <div className="description-ITBA">
                  <p>Developing the right product and solving the right problem. Enhancing the user experience while keeping costs low has always been my north star.</p>
                  <p>
                    I have extended my knowledge to other roles to see from their
                    perspectives, allowing me to provide the artifacts they need
                    for optimal output.
                  </p>
                  <p>
                    Like a craftsman, I meticulously care for my products to
                    ensure they meet business needs.
                  </p>
                </div>
              </div>
              </div>
              <div
                  ref={supportingSkillsRef}
                  className={`animate__animated ${
                      supportingSkillsInView ? 'animate__fadeInLeft' : ''
                  }`}
                  style={{ animationDelay: '1.5s' }}
              >
                  <SupportingSkills />
              </div>
          </div>
          {/* <img className="line-icon" loading="lazy" alt="" src="/line.svg" /> */}
        </section>
      </div>
      <div className="mycert-section"> 
        <div className="section-inner-container">
          <div className="section-header">
            <h1 className="section-title">Certifications</h1>
            <p className="section-description">Dedicated to delivering results that meet or exceed industry standards</p> 
          </div>
        </div>
        <div className="mycert-content">
          <div className="certifications">
          <div className="cert">
            <img
              className="certification-icons"
              loading="lazy"
              alt=""
              src="/images/CBAP.png"
            />
            <div className="cert-description">
              <p className="certified-business">Certified Business</p>
              <p className="analysis-professional">Analysis Professional</p>
            </div>
          </div>
          <div className="cert">
            <img
              className="certification-icons"
              loading="lazy"
              alt=""
              src="/images/PSPOII.png"
            />
            <div className="cert-description">
              <p className="certified-business">Professional Scrum</p>
              <p className="analysis-professional">Product Owner II</p>
            </div>
          </div>
          <div className="cert">
            <img
              className="certification-icons"
              loading="lazy"
              alt=""
              src="/images/UIUX.png"
            />
            <div className="cert-description">
              <p className="certified-business">Google | Coursera</p>
              <p className="analysis-professional">UX Design</p>
            </div>
          </div>
          <div className="cert">
            <img
              className="certification-icons"
              loading="lazy"
              alt=""
              src="/images/PM.png"
            />
            <div className="cert-description">
              <p className="certified-business">Google | Coursera</p>
              <p className="analysis-professional">Project Management</p>
            </div>
          </div>
          <div className="cert">
            <img
              className="certification-icons"
              loading="lazy"
              alt=""
              src="/images/CTFL.png"
            />
            <div className="cert-description">
              <p className="certified-business">Certified Tester</p>
              <p className="analysis-professional">Foundation Level</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ctas">
        <button className="button-nav" onClick={() => document.getElementById('contactMeSection').scrollIntoView({ behavior: 'smooth' })}>
          <b className="contact-me">Got Questions?</b>
        </button>
    </div>
    </div>
    );
};

export default MySkill;