import React from 'react';
import BlogCard from "./BlogCard";

import './BlogList.css';
import '../pages/Blog.css';


const BlogList = props => {
    if (props.items.length === 0) {
        return (
            <div className='center'>
                <h2>No blogs found</h2>
            </div>
        );
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
  };

    return (
        // Updated className to 'cards' to match the CSS class in BlogList.css
      <div className="blog-position">
        <section className="cards">
          {props.items.map((blog) => (
            <BlogCard
              key={blog.id} 
              image={blog.imageURL || "/images/dummy-blog-img.jpg"} 
              title={blog.title}
              content={blog.content}
              topic={blog.topic}
              createdDate={formatDate(blog.createdDate)} 
            />
          ))}
        </section>
      </div>
    );
};


export default BlogList;