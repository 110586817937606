import { React, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';


import './navbar.css';
import '../../index.css';


const Navbar = () => {
  const history = useHistory();
  const location = useLocation();

  const handleServicesClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#mySkillSection') {
      history.push(`${targetPath}#mySkillSection`);
    } else {
      scrollToSection('mySkillSection');
    }
  };

  const handlePortfolioClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#myPortfolioSection') {
      history.push(`${targetPath}#myPortfolioSection`);
    } else {
      scrollToSection('myPortfolioSection');
    }
  };

  const handleContactClick = (e) => {
    e.preventDefault();
    const targetPath = '/';
    if (location.pathname !== targetPath || location.hash !== '#contactMeSection') {
      history.push(`${targetPath}#contactMeSection`);
    } else {
      scrollToSection('contactMeSection');
    }
  };

  const handleAboutClick = (e) => {
    e.preventDefault(); 
    history.push('/'); 
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  };

  const handleBlogClick = (e) => {
    e.preventDefault(); 
    history.push('/blog'); 
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  };



  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = 40;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
    } else {
      console.error(`Target section ${sectionId} not found.`);
    }
  };

  useEffect(() => {
    if (location.hash === '#mySkillSection') {
      scrollToSection('mySkillSection');
    } else if (location.hash === '#myPortfolioSection') {
      scrollToSection('myPortfolioSection');
    } else if (location.hash === '#contactMeSection') {
      scrollToSection('contactMeSection');
    } 
  }, [location.hash]);


  return (
    <header className="navbar">
      <div className="container1192 flex">
        <div className="brand" onClick={handleAboutClick} style={{ cursor: 'pointer' }}>
          <img src='/images/logo.png'></img>
        </div>
        <nav className="page-links">
          <div className="navcircle" />
          <nav className="links-container">
            <Link className="about1" to="/" onClick={handleAboutClick}>About</Link>
            <Link className="services" to="/" onClick={handleServicesClick}>Services</Link>
            <Link className="portfolio" to="/" onClick={handlePortfolioClick}>Portfolio</Link>
            <Link className="blog" to="/blog" onClick={handleBlogClick}>Blog</Link>
          </nav>
        </nav>
        <button className="button-nav">
          <b className="lets-talk" to="/" onClick={handleContactClick}>Let’s Talk</b>
        </button>
      </div>
    </header>
  );
};

export default Navbar;
