import React from 'react';

import './AboutMe.css';

const AboutMe = () => {
    return (
        <div className="root">
          <footer className="about">
            <div className="about-content">
              <div className="about-info">
                <div className="info-container">
                  <h1 className="title">About Me</h1>
                  <div className="description">
                    <p>Driven by cost. Inspired by quality. I'm passionate about creating beautiful, high-quality products.</p>
                    <p>With expertise in IT Business Analysis and strong skills in UI/UX Design, Product Management, and programming, I continuously learn to deliver the best products within your budget.</p>
                    {/* <p>In outsourced roles, I excel at quickly adapting to new challenges, learning new domains continuously, and facing unknowns. In product-focused roles, I have developed a deep understanding of system design and data structures.</p> */}
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="info-01">
                  <b className="info-data">23</b>
                  <div className="years-of-experience-wrapper">
                    <b className="years-of-experience">Projects Done</b>
                  </div>
                </div>
                <div className="info-02">
                  <b className="b">3.5+</b>
                  <div className="years-of-experience-container">
                    <b className="years-of-experience1">Years of Experience</b>
                  </div>
                </div>
                <div className="info-03">
                  <b className="b1">13</b>
                  <div className="years-of-experience-frame">
                    <b className="years-of-experience2">Clients Served</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-background">
              <div className="background" />
            </div>
          </footer>
        </div>
      );
    };
    

export default AboutMe;
