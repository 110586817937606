import React, { useState } from 'react';

import './BlogCardHero.css';
import '../../index.css';
import DevelopmentWarningPopup from '../../shared/components/WarningDialog';



const BlogCardHero = ({ image, title, content, createdDate, topic }) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [isPopupVisible, setPopupVisible] = useState(false);

    const handleButtonClick = () => {
      setPopupVisible(true);
    };

    const handleClosePopup = () => {
      setPopupVisible(false);
    };

  return (
    <div className="blog-card-hero">
      <img src={image} alt="" className="hero-image"/>
      <div className='blog-hero-container'>
        <div className="blog-hero-content">
            <div className='blog-hero-meta'>
                <span className='blog-hero-date'>{formatDate(createdDate)}, {topic}</span>
                <b className="editors-pick">Editor’s Pick</b>
            </div>
            <h2 className='blog-hero-title'>{title}</h2>
            <p className='blog-hero-desc'>{content}</p>
            <button className="ctalink" onClick={handleButtonClick}>
                <div className="read-more-wrapper">
                    <b className="read-more">Read More</b>
                </div>
                <svg
                  className="arrow-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 17"
                  fill="#000"
                  width="18px"
                  height="18px"
                  >
                  <path d="M5.94 14.1005L10.2867 9.75386C10.8 9.24053 10.8 8.40053 10.2867 7.88719L5.94 3.54053" />
                </svg>
            </button>
        </div>
        <DevelopmentWarningPopup isOpen={isPopupVisible} onClose={handleClosePopup} />
      </div>
    </div>
  );
};

export default BlogCardHero;