import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text
} from '@chakra-ui/react';

const PrivacyPolicyModal = ({ isOpen, onClose, size }) => {
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size={size} isCentered>
      <ModalOverlay  />
      <ModalContent 
          bg="white" 
          p={6} 
          borderRadius="4px" 
          boxShadow="lg" 
          width="90%" 
          maxWidth="600px" 
          maxHeight="90vh"
          overflowY="auto"
          >
        <Flex justifyContent="space-between" alignItems="stretch">
          <ModalHeader whiteSpace="nowrap">Privacy Policy</ModalHeader>
          <ModalCloseButton position="relative"  />
        </Flex>
        <ModalBody>
          <Flex alignItems="center" mb="1rem">
            <Text fontWeight="bold" mr="1rem">
              Effective Date:
            </Text>
            <Text>
              25th July 2024
            </Text>
          </Flex>
          <Text fontWeight="semibold" mr="1rem" mt="0.5rem" >1. Information Collection</Text>
          <p>We collect personal information such as your name, email address, and phone number via our contact form and email subscription form.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >2. Use of Information</Text>
          <p>The collected information is used solely for personal communication and sending blogs. We do not share your information with any third party.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >3. Data Retention</Text>
          <p>Your information is retained for as long as necessary to fulfill the purposes outlined in this policy. You can request deletion of your data at any time by contacting us.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >4. Security</Text>
          <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >5. Changes to this Policy</Text>
          <p>We may update this policy from time to time. We will notify you of any changes by posting the new policy on our website or via email if you subscribed.</p>
          <Text fontWeight="semibold" mr="1rem" mt="1rem" >6. Contact Us</Text>
          <p>If you have any questions about this Privacy Policy, please contact us at tuongpt3@gmail.com.</p>
        </ModalBody>
        <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>        
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrivacyPolicyModal;
