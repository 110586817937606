import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Adjust the import path as necessary
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import NavBar from './components/navbar.js';
import LandingPage from './landingpage/pages/LandingPage'; // Adjust the import path as necessary
import Blog from './blogs/pages/Blog'; // Adjust the import path as necessary
import 'animate.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

ReactDOM.render(
  <React.StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        <ChakraProvider>
              <App />
          </ChakraProvider>
      </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);